// client/src/pages/AppHome.tsx

import React, { useState, useEffect } from 'react';
import { Send } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import Image from '@/components/shared/Image';
import { BotCard } from '@/components/bots/BotCard';

interface Bot {
  id: string;
  name: string;
  description: string;
  category: string;
}

interface Message {
  id: string;
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
  recommendedBots?: Bot[];
}

const INITIAL_MESSAGE: Message = {
  id: Date.now().toString(),
  role: 'assistant',
  content: "Hi there! 👋 I'm Botli, your AI companion. Let me help you find the perfect AI assistant for your needs. Just start a conversation below!",
  timestamp: new Date()
};

const INITIAL_PLACEHOLDER = "What do you want to accomplish today?";
const FOLLOW_UP_PLACEHOLDER = "Send a message to Botli...";

const recommendedBots: Bot[] = [
  {
    id: 'writer-bot',
    name: 'Writing Assistant',
    description: 'Your personal writing and editing companion',
    category: 'Writing'
  },
  {
    id: 'research-bot',
    name: 'Research Helper',
    description: 'Accelerate your research process',
    category: 'Analysis'
  },
  {
    id: 'marketing-bot',
    name: 'Marketing Expert',
    description: 'Create compelling marketing strategies',
    category: 'Marketing'
  }
];

export default function AppHome() {
  const [count, setCount] = useState<number>(0);
  const [text, setText] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([INITIAL_MESSAGE]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fullText = "Your Personal AI Assistant Hub";
  const hasUserSentMessage = messages.some(message => message.role === 'user');
  const currentPlaceholder = hasUserSentMessage ? FOLLOW_UP_PLACEHOLDER : INITIAL_PLACEHOLDER;

  useEffect(() => {
    const typeText = () => {
      if (text.length < fullText.length) {
        setText(fullText.slice(0, text.length + 1));
      }
    };
    const timer = setInterval(typeText, 50);
    return () => clearInterval(timer);
  }, [text]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => (prevCount < 20 ? prevCount + 1 : prevCount));
    }, 100);
    return () => clearInterval(timer);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim() || isLoading) return;

    const newUserMessage: Message = {
      id: Date.now().toString(),
      role: 'user',
      content: inputValue,
      timestamp: new Date()
    };

    setMessages(prev => [...prev, newUserMessage]);
    setInputValue('');
    setIsLoading(true);

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));

      const botResponse: Message = {
        id: (Date.now() + 1).toString(),
        role: 'assistant',
        content: 'Here are some recommended bots that might help you:',
        timestamp: new Date(),
        recommendedBots: recommendedBots
      };

      setMessages(prev => [...prev, botResponse]);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e as unknown as React.FormEvent);
    }
  };

  return (
    <div className="relative overflow-hidden py-16 sm:py-24 lg:py-32">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-col items-center text-center gap-8">
          <div className="inline-flex items-center rounded-full border border-border bg-card px-4 py-1.5">
            <span className="text-sm text-muted-foreground">
              ✨ Discover {count}+ AI assistants
            </span>
          </div>

          <div className="flex flex-col items-center text-center gap-8 mb-12">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-balance">
              {text}
              <span className="animate-blink">|</span>
            </h1>
            <p className="text-lg sm:text-xl text-muted-foreground max-w-2xl text-center">
              Start a conversation below to get personalized AI assistant recommendations tailored to your needs.
            </p>
          </div>

          <div className="w-full max-w-3xl mb-4 space-y-4 relative">
            {/* Floating Avatar - Only shown once */}
            {messages.length > 0 && (
              <div className="absolute left-0 top-0 -translate-x-[calc(100%+2rem)] w-32 h-32">
                <Image
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png"
                  alt="Botli Assistant"
                  width={128}
                  height={128}
                  className="rounded-full border-4 border-primary/20 shadow-lg hover:scale-105 transition-transform"
                />
              </div>
            )}

            {/* Messages */}
            {messages.map((message, index) => (
              <div key={message.id} className="space-y-4">
                <div 
                  className={`flex items-start gap-4 ${
                    message.role === 'user' ? 'flex-row-reverse' : 'flex-row'
                  } ${index === 0 ? 'animate-fadeInUp' : ''}`}
                >
                  <div 
                    className={`p-4 rounded-lg ${
                      message.role === 'user' 
                        ? 'bg-primary text-primary-foreground ml-auto max-w-[80%]' 
                        : 'bg-muted text-foreground mr-auto max-w-[80%]'
                    } ${index === 0 ? 'animate-fadeInUp animation-delay-300' : ''}`}
                  >
                    {message.content}
                  </div>
                </div>

                {message.recommendedBots && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                    {message.recommendedBots.map((bot) => (
                      <BotCard
                        key={bot.id}
                        bot={bot}
                        onClick={() => console.log(`Selected bot: ${bot.name}`)}
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="w-full max-w-3xl">
            <form onSubmit={handleSubmit} className="relative">
              <Textarea
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={currentPlaceholder}
                className="w-full h-40 p-4 pr-12 text-lg bg-input text-foreground placeholder-muted-foreground border border-input rounded-lg focus:outline-none focus:ring-2 focus:ring-primary resize-none"
              />
              <Button
                type="submit"
                className="absolute right-4 bottom-4 rounded-full bg-primary text-primary-foreground hover:bg-primary/90"
                size="icon"
                aria-label="Send message"
                disabled={isLoading}
              >
                <Send className="w-5 h-5" />
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-background via-transparent to-background pointer-events-none"></div>
    </div>
  );
}
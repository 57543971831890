import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Bot, Home, BarChart, PenTool, Code, Megaphone, ChevronLeft, ChevronRight, Settings, Bell, User } from 'lucide-react'

const categories = [
  { name: 'Home', icon: Home, path: '/app' },
  { name: 'Writing', icon: PenTool, path: '/app/bots/writing' },
  { name: 'Analysis', icon: BarChart, path: '/app/bots/analysis' },
  { name: 'Design', icon: Bot, path: '/app/bots/design' },
  { name: 'Development', icon: Code, path: '/app/bots/development' },
  { name: 'Marketing', icon: Megaphone, path: '/app/bots/marketing' }
]

type AppSidebarProps = {
  isOpen: boolean
  onClose: () => void
  isCollapsed: boolean
  onToggleCollapse: () => void
}

export default function AppSidebar({ isOpen, onClose, isCollapsed, onToggleCollapse }: AppSidebarProps) {
  const location = useLocation()
  const [activeCategory, setActiveCategory] = useState('')

  useEffect(() => {
    setActiveCategory(location.pathname)
  }, [location])

  return (
    <aside
      className={`h-full bg-[#1E1E1E] flex flex-col transition-all duration-300 ease-in-out ${
        isCollapsed ? 'w-16' : 'w-60'
      } ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}
    >
      <div className="flex items-center justify-between h-16 px-4">
        <div className={`flex items-center ${isCollapsed ? 'justify-center w-full' : ''}`}>
          <Bot className="w-8 h-8 text-purple-500 flex-shrink-0" />
          {!isCollapsed && (
            <span className="ml-3 text-lg font-semibold text-white">botli.st</span>
          )}
        </div>
        {!isCollapsed && (
          <button 
            onClick={onClose}
            className="p-2 lg:hidden rounded-full text-gray-400 hover:text-white hover:bg-[#2C2D31] focus:outline-none"
            aria-label="Close sidebar"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
        )}
      </div>

      <nav className="flex-1 py-4 overflow-y-auto">
        {categories.map((category) => (
          <Link
            key={category.name}
            to={category.path}
            className={`flex items-center h-12 mx-2 rounded-lg text-sm transition-all duration-200 group ${
              activeCategory === category.path
                ? 'bg-purple-600 text-white'
                : 'text-gray-400 hover:text-white hover:bg-[#2C2D31]'
            }`}
            onClick={() => setActiveCategory(category.path)}
          >
            <div className={`flex items-center ${isCollapsed ? 'justify-center w-full' : 'px-4'}`}>
              <category.icon className={`w-5 h-5 transition-colors ${
                activeCategory === category.path
                  ? 'text-white'
                  : 'text-gray-400 group-hover:text-white'
              }`} />
              {!isCollapsed && (
                <span className="ml-3">{category.name}</span>
              )}
            </div>
          </Link>
        ))}
      </nav>

      <div className="py-2 space-y-1">
        {['notifications', 'profile', 'settings'].map((item) => (
          <Link
            key={item}
            to={`/app/${item}`}
            className={`flex items-center h-12 mx-2 rounded-lg text-sm transition-all duration-200 group ${
              activeCategory === `/app/${item}`
                ? 'bg-purple-600 text-white'
                : 'text-gray-400 hover:text-white hover:bg-[#2C2D31]'
            }`}
            onClick={() => setActiveCategory(`/app/${item}`)}
          >
            <div className={`flex items-center ${isCollapsed ? 'justify-center w-full' : 'px-4'}`}>
              {item === 'notifications' && <Bell className={`w-5 h-5 transition-colors ${
                activeCategory === `/app/${item}`
                  ? 'text-white'
                  : 'text-gray-400 group-hover:text-white'
              }`} />}
              {item === 'profile' && <User className={`w-5 h-5 transition-colors ${
                activeCategory === `/app/${item}`
                  ? 'text-white'
                  : 'text-gray-400 group-hover:text-white'
              }`} />}
              {item === 'settings' && <Settings className={`w-5 h-5 transition-colors ${
                activeCategory === `/app/${item}`
                  ? 'text-white'
                  : 'text-gray-400 group-hover:text-white'
              }`} />}
              {!isCollapsed && (
                <span className="ml-3 capitalize">{item}</span>
              )}
            </div>
          </Link>
        ))}
      </div>

      <div className="mt-auto">
        <button
          onClick={onToggleCollapse}
          className="flex items-center justify-center w-full h-12 text-gray-400 hover:text-white hover:bg-[#2C2D31] transition-colors duration-200"
          aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
        >
          {isCollapsed ? <ChevronRight className="w-5 h-5" /> : <ChevronLeft className="w-5 h-5" />}
        </button>

        <div className={`py-4 px-2 text-xs text-gray-400 text-center transition-all duration-300 ${
          isCollapsed ? 'opacity-0' : 'opacity-100'
        }`}>
          <span className="sr-only">Copyright</span>
          <span aria-hidden={isCollapsed}>© 2025 botli.st</span>
        </div>
      </div>
    </aside>
  )
}
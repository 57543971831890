import { Link } from 'react-router-dom'
import { PenTool, Code, Bot, BarChart, Megaphone } from 'lucide-react'
import { Card, CardContent } from '@/components/ui/card'

const categories = [
  { id: 'writing', name: 'Writing', icon: PenTool, description: 'Create content with AI assistance' },
  { id: 'development', name: 'Development', icon: Code, description: 'AI-powered development tools' },
  { id: 'analysis', name: 'Analysis', icon: BarChart, description: 'Data analysis and insights' },
  { id: 'marketing', name: 'Marketing', icon: Megaphone, description: 'Marketing automation and optimization' }
]

export default function BotsOverview() {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-8">Bot Categories</h1>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {categories.map((category) => {
          const Icon = category.icon
          return (
            <Link key={category.id} to={category.id}>
              <Card className="h-full transition-colors hover:border-primary">
                <CardContent className="p-6 flex flex-col gap-4">
                  <Icon className="w-8 h-8 text-primary" />
                  <div>
                    <h3 className="text-lg font-semibold">{category.name}</h3>
                    <p className="text-sm text-muted-foreground">{category.description}</p>
                  </div>
                </CardContent>
              </Card>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

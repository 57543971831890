import { Card, CardContent } from '../ui/card'
import { Badge } from '../ui/badge'
import Image from '../shared/Image'

// Sample bot data
const bots = [
  { id: 1, name: "WriterBot", description: "AI-powered content creation assistant", category: "Writing", avatar: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png" },
  { id: 2, name: "AnalystBot", description: "Data analysis and visualization expert", category: "Analytics", avatar: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png" },
  { id: 3, name: "DesignBot", description: "Creative design and mockup generator", category: "Design", avatar: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png" },
  { id: 4, name: "CodeBot", description: "Intelligent coding assistant and debugger", category: "Development", avatar: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png" },
  { id: 5, name: "MarketingBot", description: "Digital marketing strategy optimizer", category: "Marketing", avatar: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png" },
  { id: 6, name: "SalesBot", description: "AI-driven sales funnel optimizer", category: "Sales", avatar: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png" },
  { id: 7, name: "SupportBot", description: "24/7 customer support assistant", category: "Customer Service", avatar: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png" },
  { id: 8, name: "ResearchBot", description: "Academic research and citation helper", category: "Research", avatar: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png" },
]

export default function BotShowcase() {
  return (
    <section className="relative overflow-hidden border-t border-border bg-card">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
        <div className="flex flex-col items-center text-center gap-8 mb-16">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-foreground">
            Meet Your AI Assistants
          </h2>
          <p className="text-lg sm:text-xl text-muted-foreground max-w-2xl">
            Browse through our collection of specialized AI bots, each crafted for specific tasks and industries.
          </p>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {bots.map((bot) => (
            <Card key={bot.id} className="bg-background border-border hover:border-primary transition-colors">
              <CardContent className="p-6">
                <div className="flex flex-col items-center gap-4">
                  <div className="h-20 w-20 rounded-full bg-primary/20 flex items-center justify-center overflow-hidden">
                    <Image 
                      src={bot.avatar} 
                      alt={bot.name} 
                      width={80} 
                      height={80} 
                      className="object-cover w-full h-full"
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="text-lg font-semibold text-foreground mb-2">{bot.name}</h3>
                    <p className="text-sm text-muted-foreground mb-3">
                      {bot.description}
                    </p>
                    <Badge variant="secondary" className="bg-primary/20 text-primary">
                      {bot.category}
                    </Badge>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  )
}

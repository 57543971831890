import { Bot } from 'lucide-react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className="border-t border-border bg-card">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center gap-2">
            <Bot className="h-8 w-8 text-primary" />
            <span className="text-xl font-bold text-foreground">botli.st</span>
          </Link>
          <p className="text-muted-foreground text-sm">
            © 2025 botli.st. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
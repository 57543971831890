import { Button } from '../ui/button'
import { CheckCircle2 } from 'lucide-react'

export default function PricingSection() {
  return (
    <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
      <div className="relative rounded-3xl overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-primary to-blue-600 animate-gradient-x"></div>
        <div className="relative z-10 p-8 md:p-16">
          <div className="flex flex-col items-center text-center gap-8">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white max-w-2xl mb-4">
              Choose the Perfect Plan for Your Needs
            </h2>
            <p className="text-lg sm:text-xl text-white/80 max-w-2xl mb-8">
              Unlock the power of AI with our flexible pricing options. Start transforming your workflow today!
            </p>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8 w-full">
              <div className="bg-card backdrop-blur-md rounded-lg p-6">
                <h3 className="text-2xl font-bold text-foreground mb-4">Basic Plan</h3>
                <div className="text-4xl font-bold text-foreground mb-2">$9.99<span className="text-xl">/month</span></div>
                <p className="text-muted-foreground mb-4">+ VAT where applicable</p>
                <ul className="space-y-2 text-muted-foreground text-left mb-6">
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    Access to 10 AI Bots
                  </li>
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    Limited usage
                  </li>
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    Regular updates
                  </li>
                </ul>
                <Button size="lg" className="w-full bg-white text-primary hover:bg-white/90">
                  Get Started
                </Button>
              </div>
              <div className="bg-card backdrop-blur-md rounded-lg p-6 border-2 border-primary">
                <h3 className="text-2xl font-bold text-foreground mb-4">Pro Plan</h3>
                <div className="text-4xl font-bold text-foreground mb-2">$29.99<span className="text-xl">/month</span></div>
                <p className="text-muted-foreground mb-4">+ VAT where applicable</p>
                <ul className="space-y-2 text-muted-foreground text-left mb-6">
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    Access to all AI Bots
                  </li>
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    Unlimited usage
                  </li>
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    Priority support
                  </li>
                </ul>
                <Button size="lg" className="w-full bg-primary text-primary-foreground hover:bg-primary/90">
                  Get Started
                </Button>
              </div>
              <div className="bg-card backdrop-blur-md rounded-lg p-6">
                <h3 className="text-2xl font-bold text-foreground mb-4">Enterprise</h3>
                <div className="text-4xl font-bold text-foreground mb-2">Custom</div>
                <p className="text-muted-foreground mb-4">Tailored to your needs</p>
                <ul className="space-y-2 text-muted-foreground text-left mb-6">
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    Custom AI Bot development
                  </li>
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    Dedicated account manager
                  </li>
                  <li className="flex items-center gap-2">
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                    24/7 premium support
                  </li>
                </ul>
                <Button size="lg" className="w-full bg-white text-primary hover:bg-white/90">
                  Contact Sales
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import Image from "../shared/Image";

export default function HeroSection() {
  const [count, setCount] = useState<number>(0);
  const [text, setText] = useState<string>("");
  const fullText = "Meet your new AI workforce on botli.st";

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => (prevCount < 20 ? prevCount + 1 : prevCount));
    }, 100);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const typeText = () => {
      if (text.length < fullText.length) {
        setText(fullText.slice(0, text.length + 1));
      }
    };
    const timer = setInterval(typeText, 50);
    return () => clearInterval(timer);
  }, [text]);

  return (
    <section className="relative overflow-hidden py-16 sm:py-24 lg:py-32">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-col items-center text-center gap-8">
          <div className="inline-flex items-center rounded-full border border-border bg-card px-4 py-1.5">
            <span className="text-sm text-muted-foreground">
              ✨ We just launched {count}+ new AI bots
            </span>
          </div>
          <div className="grid lg:grid-cols-2 gap-12 lg:gap-16 items-center max-w-6xl w-full">
            <div className="flex flex-col items-center lg:items-start text-center lg:text-left gap-8">
              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-balance">
                {text}
                <span className="animate-blink">|</span>
              </h1>
              <p className="text-lg sm:text-xl text-muted-foreground max-w-2xl">
                Our carefully crafted and real-world tested AI assistants are
                here to become your personal workforce. Don't hire a team to get
                things done – use botli.st
              </p>
              <Button
                size="lg"
                className="bg-primary hover:bg-primary/90 text-primary-foreground w-full sm:w-auto"
              >
                <Link to="/app">Browse AI Bots</Link>
              </Button>
            </div>
            <div className="flex justify-center lg:justify-end items-center w-full">
              <div className="relative w-full max-w-[480px] aspect-[1/1] glow-effect">
                <Image
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ihZJD0dflXmj3KKILxnXUK9tnoExvg.png"
                  alt="botli.st - Your AI assistant"
                  width={480}
                  height={480}
                  className="w-full h-full object-cover object-[center_20%] drop-shadow-2xl rounded-3xl border-4 border-primary/20"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-background via-transparent to-background pointer-events-none"></div>
    </section>
  );
}

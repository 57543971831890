// client/src/pages/bots/BotPage.tsx
import { useParams } from 'react-router-dom';
import { BreadcrumbNav } from '@/components/navigation/BreadcrumbNav';

// Import all bot components
const botModules = {
  writing: import.meta.glob('/src/pages/bots/writing/*.tsx', { eager: true }),
  development: import.meta.glob('/src/pages/bots/development/*.tsx', { eager: true }),
  analysis: import.meta.glob('/src/pages/bots/analysis/*.tsx', { eager: true }),
  marketing: import.meta.glob('/src/pages/bots/marketing/*.tsx', { eager: true }),
  design: import.meta.glob('/src/pages/bots/design/*.tsx', { eager: true })
};

export default function BotPage() {
  const { category, botId } = useParams<{ category: string; botId: string }>();

  if (!category || !botId) {
    return <div>Invalid bot path</div>;
  }

  const categoryModules = botModules[category as keyof typeof botModules];
  if (!categoryModules) {
    return <div>Category not found</div>;
  }

  const botPath = Object.keys(categoryModules).find(path => 
    path.toLowerCase().includes(botId.toLowerCase())
  );

  if (!botPath) {
    return <div>Bot not found</div>;
  }

  const BotComponent = (categoryModules[botPath] as { default: React.ComponentType }).default;

  return (
    <div className="container mx-auto p-6">
      <BreadcrumbNav />
      <div className="mt-6">
        <BotComponent />
      </div>
    </div>
  );
}
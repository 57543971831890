// client/src/components/bots/BotCard.tsx

import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { PenTool, Code, BarChart, Megaphone, Bot, Palette } from 'lucide-react';

interface Bot {
  id: string;
  name: string;
  description: string;
  category: string;
}

interface BotCardProps {
  bot: Bot;
  onClick?: () => void;
}

const categoryConfig = {
  'Writing': {
    icon: PenTool,
    color: 'border-purple-500',
    bgColor: 'bg-purple-500/10',
  },
  'Development': {
    icon: Code,
    color: 'border-blue-500',
    bgColor: 'bg-blue-500/10',
  },
  'Analysis': {
    icon: BarChart,
    color: 'border-green-500',
    bgColor: 'bg-green-500/10',
  },
  'Marketing': {
    icon: Megaphone,
    color: 'border-orange-500',
    bgColor: 'bg-orange-500/10',
  },
  'Design': {
    icon: Palette,
    color: 'border-pink-500',
    bgColor: 'bg-pink-500/10',
  },
  'default': {
    icon: Bot,
    color: 'border-gray-500',
    bgColor: 'bg-gray-500/10',
  }
};

export function BotCard({ bot, onClick }: BotCardProps) {
  const config = categoryConfig[bot.category as keyof typeof categoryConfig] || categoryConfig.default;
  const Icon = config.icon;

  return (
    <Card 
      className={`hover:border-2 ${config.color} transition-all cursor-pointer`}
      onClick={onClick}
    >
      <CardContent className="p-6">
        <div className="flex items-center gap-4">
          <div className={`h-12 w-12 rounded-lg ${config.bgColor} flex items-center justify-center`}>
            <Icon className={`w-6 h-6 ${config.color.replace('border-', 'text-')}`} />
          </div>
          <div>
            <h3 className="font-semibold">{bot.name}</h3>
            <p className="text-sm text-muted-foreground mb-2">
              {bot.description}
            </p>
            <Badge variant="secondary" className={`${config.bgColor} ${config.color.replace('border-', 'text-')}`}>
              {bot.category}
            </Badge>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
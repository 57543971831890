// client/src/App.tsx
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ThemeProvider } from "@/components/theme-provider"
import RootLayout from '@/components/layout/RootLayout'
import Layout from '@/components/layout/Layout'
import AppLayout from '@/components/layout/AppLayout'
import Home from '@/pages/Home'
import AppHome from '@/pages/AppHome'
import BotsOverview from '@/pages/bots/BotsOverview'
import Category from '@/pages/bots/Category'
import BotPage from '@/pages/bots/BotPage'
import { routerConfig } from '@/lib/router-config'

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {
          path: '',
          element: <Layout />,
          children: [
            {
              index: true,
              element: <Home />,
            }
          ]
        },
        {
          path: 'app',
          element: <AppLayout />,
          children: [
            {
              index: true,
              element: <AppHome />,
            },
            {
              path: 'bots',
              children: [
                {
                  index: true,
                  element: <BotsOverview />
                },
                {
                  path: ':category',
                  element: <Category />
                },
                {
                  path: ':category/:botId',
                  element: <BotPage />
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  routerConfig
)

export default function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}
import { Card, CardContent } from '../ui/card'
import { Sparkles, Zap, Users } from 'lucide-react'

export default function FeatureGrid() {
  return (
    <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24 border-t border-border">
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <Card className="bg-card border-border hover:shadow-lg hover:border-primary transition-all duration-300">
          <CardContent className="p-6 flex flex-col gap-4">
            <Sparkles className="h-12 w-12 text-primary" />
            <h3 className="text-xl font-bold text-foreground">AI-Powered Tasks</h3>
            <p className="text-muted-foreground">
              Get your work done faster with our specialized AI bots trained for specific tasks.
            </p>
          </CardContent>
        </Card>
        <Card className="bg-card border-border hover:shadow-lg hover:border-primary transition-all duration-300">
          <CardContent className="p-6 flex flex-col gap-4">
            <Zap className="h-12 w-12 text-[#F59E0B]" />
            <h3 className="text-xl font-bold text-foreground">Lightning Fast</h3>
            <p className="text-muted-foreground">
              No more waiting around. Our bots deliver results in seconds, not hours.
            </p>
          </CardContent>
        </Card>
        <Card className="bg-card border-border hover:shadow-lg hover:border-primary transition-all duration-300">
          <CardContent className="p-6 flex flex-col gap-4">
            <Users className="h-12 w-12 text-[#3B82F6]" />
            <h3 className="text-xl font-bold text-foreground">Team Ready</h3>
            <p className="text-muted-foreground">
              Integrate our bots with your existing workflow and team collaboration tools.
            </p>
          </CardContent>
        </Card>
      </div>
    </section>
  )
}

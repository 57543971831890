import { Link } from 'react-router-dom'
import { Button } from "../ui/button"
import { Bot, Menu, X } from 'lucide-react'
import * as React from 'react'

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  // Close menu when clicking outside
  React.useEffect(() => {
    if (isMenuOpen) {
      // Prevent scrolling when menu is open
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isMenuOpen])

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  return (
    <header className="border-b border-border bg-card/95 backdrop-blur supports-[backdrop-filter]:bg-card/60 sticky top-0 z-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <Link to="/" className="flex items-center gap-2">
            <Bot className="h-8 w-8 text-primary" />
            <span className="text-xl font-bold text-foreground">botli.st</span>
          </Link>
          <nav className="hidden md:flex items-center gap-4">
            <Link to="/app">
              <Button variant="ghost" className="text-muted-foreground hover:text-foreground">
                Go to App
              </Button>
            </Link>
            <Button className="bg-primary hover:bg-primary/90 text-primary-foreground">
              Get Started
            </Button>
          </nav>
          <button 
            className="md:hidden p-2 rounded-md hover:bg-muted"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <Menu className="h-6 w-6" />
          </button>
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black/50 z-[100] md:hidden">
          {/* Mobile Menu Panel */}
          <div className="fixed right-0 top-0 h-screen w-full sm:w-[320px] bg-background shadow-lg overflow-y-auto">
            {/* Mobile Menu Header */}
            <div className="sticky top-0 flex items-center justify-between p-4 border-b border-border bg-background">
              <Link to="/" className="flex items-center gap-2" onClick={toggleMenu}>
                <Bot className="h-6 w-6 text-primary" />
                <span className="font-semibold text-foreground">botli.st</span>
              </Link>
              <button 
                onClick={toggleMenu}
                className="p-2 rounded-md hover:bg-muted"
                aria-label="Close menu"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            {/* Mobile Menu Content */}
            <div className="flex flex-col h-[calc(100vh-4rem)]">
              <div className="p-4 space-y-6 flex-1">
                {/* Navigation Links */}
                <nav className="space-y-2">
                  <Link
                    to="/app"
                    onClick={toggleMenu}
                    className="flex items-center w-full p-4 rounded-md hover:bg-muted text-muted-foreground hover:text-foreground transition-colors"
                  >
                    Go to App
                  </Link>
                </nav>

                {/* Action Buttons */}
                <div className="space-y-3 pt-4 border-t border-border">
                  <Button 
                    className="w-full bg-primary hover:bg-primary/90 text-primary-foreground p-6"
                    onClick={toggleMenu}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}
import { Link, useParams } from 'react-router-dom'
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { BreadcrumbNav } from '@/components/navigation/BreadcrumbNav'
import { Badge } from '@/components/ui/badge'
import { useState } from 'react'

// Import all bot components from each category
const botModules = {
  writing: import.meta.glob('/src/pages/bots/writing/*.tsx', { eager: true }),
  development: import.meta.glob('/src/pages/bots/development/*.tsx', { eager: true }),
  analysis: import.meta.glob('/src/pages/bots/analysis/*.tsx', { eager: true }),
  marketing: import.meta.glob('/src/pages/bots/marketing/*.tsx', { eager: true }),
  design: import.meta.glob('/src/pages/bots/design/*.tsx', { eager: true })
}

// Type for bot metadata
interface BotMeta {
  name: string
  description: string
  tags: string[]
  emoji?: string
}

interface BotModule {
  default: React.ComponentType
  botMeta?: BotMeta
}

// Helper function to extract metadata from file path
const extractBotInfo = (
  path: string,
  category: string,
): {
  id: string
  name: string
  path: string
  category: string
  meta: BotMeta
} => {
  const fileName = path.split('/').pop()?.replace('.tsx', '') || ''
  const displayName = fileName.replace(/([A-Z])/g, ' $1').trim()

  // Get metadata if module is eagerly loaded
  const module = botModules[category as keyof typeof botModules][path] as BotModule
  let meta: BotMeta

  if (module?.botMeta) {
    meta = module.botMeta
  } else {
    // Default metadata if not specified
    meta = {
      name: displayName,
      description: `AI-powered ${displayName.toLowerCase()} assistant`,
      tags: [category],
      emoji: '🤖'
    }
  }

  return {
    id: fileName.toLowerCase(),
    name: meta.name || displayName,
    path: `/app/bots/${category}/${fileName.toLowerCase()}`,
    category,
    meta
  }
}

export default function Category() {
  const { category } = useParams<{ category: string }>()
  const [searchQuery, setSearchQuery] = useState('')

  // Check if category exists
  const validCategory = category as keyof typeof botModules
  const categoryExists = validCategory && botModules[validCategory]

  // Get bots for the current category
  const bots = categoryExists
    ? Object.keys(botModules[validCategory]).map((path) =>
        extractBotInfo(path, category || '')
      )
    : []

  const filteredBots = bots.filter(
    (bot) =>
      bot.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      bot.meta?.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      bot.meta?.tags.some((tag) =>
        tag.toLowerCase().includes(searchQuery.toLowerCase())
      )
  )

  return (
    <div className="container mx-auto p-6">
      <div className="mb-8">
        <BreadcrumbNav />
        <h1 className="text-2xl font-bold mb-4 capitalize">{category} Bots</h1>
        {categoryExists && (
          <input
            type="text"
            placeholder="Search bots..."
            className="w-full max-w-md p-2 border rounded"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        )}
      </div>

      {!categoryExists ? (
        <div className="text-center py-12">
          <p className="text-muted-foreground">
            The category "{category}" is not available yet. Please select a different category.
          </p>
        </div>
      ) : filteredBots.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-muted-foreground">
            No bots available in the {category} category yet.
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredBots.map((bot) => (
            <Card
              key={bot.id}
              className="flex flex-col hover:border-primary transition-colors"
            >
              <CardContent className="flex-1 p-6">
                <div className="flex items-center gap-3 mb-4">
                  <span className="text-2xl">{bot.meta?.emoji}</span>
                  <h2 className="text-xl font-semibold">{bot.name}</h2>
                </div>
                <p className="text-muted-foreground mb-4">
                  {bot.meta?.description}
                </p>
                <div className="flex flex-wrap gap-2">
                  {bot.meta?.tags.map((tag) => (
                    <Badge key={tag} variant="secondary">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </CardContent>
              <CardFooter className="p-6 pt-0">
                <Link to={bot.path} className="w-full">
                  <Button className="w-full">Open Bot</Button>
                </Link>
              </CardFooter>
            </Card>
          ))}
        </div>
      )}
    </div>
  )
}
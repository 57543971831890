import * as React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Fragment } from 'react'
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const breadcrumbMap: Record<string, string> = {
  'app': 'Home',
  'bots': 'Bots',
  'writing': 'Writing',
  'blog': 'Blog Bot',
  'social': 'Social Media Bot',
  'email': 'Email Bot',
  'copy': 'Copywriting Bot',
  'story': 'Story Bot',
  'content': 'Content Bot'
}

export function BreadcrumbNav() {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)
  return (
    <Breadcrumb>
      <BreadcrumbList>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join('/')}`
          return (
            <Fragment key={to}>
              {index !== 0 && <BreadcrumbSeparator />}
              <BreadcrumbItem>
                {last ? (
                  <BreadcrumbPage>
                    {breadcrumbMap[value] || value}
                  </BreadcrumbPage>
                ) : (
                  <BreadcrumbLink asChild>
                    <Link to={to}>
                      {breadcrumbMap[value] || value}
                    </Link>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            </Fragment>
          )
        })}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

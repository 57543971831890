// client/src/lib/router-config.ts

export const routerConfig = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
  basename: '/', // Base URL path if your app is not hosted at root
  window: typeof window === 'undefined' ? undefined : window,
}
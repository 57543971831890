import HeroSection from '../components/home/HeroSection'
import FeatureGrid from '../components/home/FeatureGrid'
import BotShowcase from '../components/home/BotShowcase'
import FAQSection from '../components/home/FAQSection'
import PricingSection from '../components/home/PricingSection'

export default function Home() {
  return (
    <>
      <HeroSection />
      <FeatureGrid />
      <BotShowcase />
      <FAQSection />
      <PricingSection />
    </>
  )
}
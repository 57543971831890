import { ChevronRight } from 'lucide-react'

const faqs = [
  { question: "How does Botli.st work?", answer: "Botli.st provides a suite of specialized AI bots that you can integrate into your workflow. Simply choose the bot that fits your task, and it will assist you in completing it efficiently." },
  { question: "Is my data safe with Botli.st?", answer: "Yes, we take data security very seriously. All data is encrypted and we adhere to strict privacy policies. We never share or sell your information." },
  { question: "Can I customize the bots for my specific needs?", answer: "While our bots are pre-trained for specific tasks, many of them offer customization options. For enterprise clients, we also offer fully customized solutions." },
  { question: "What kind of support do you offer?", answer: "We offer 24/7 customer support for all our plans. Our Pro and All-in plans also include priority support and personalized onboarding." },
]

export default function FAQSection() {
  return (
    <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24 border-t border-border">
      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-foreground text-center mb-12">Frequently Asked Questions</h2>
      <div className="max-w-3xl mx-auto">
        {faqs.map((faq, index) => (
          <details key={index} className="group mb-4">
            <summary className="flex justify-between items-center cursor-pointer list-none p-4 rounded-lg bg-card hover:bg-accent transition-colors">
              <span className="font-medium text-foreground">{faq.question}</span>
              <ChevronRight className="w-5 h-5 text-muted-foreground group-open:rotate-90 transition-transform" />
            </summary>
            <p className="mt-4 px-4 text-muted-foreground">{faq.answer}</p>
          </details>
        ))}
      </div>
    </section>
  )
}
// client/src/pages/bots/writing/BlogBot.tsx
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ChatInterface from '@/components/chat/ChatInterface'
import type { Message } from '@/components/chat/chat'

export const botMeta = {
  name: "Blog Bot",
  description: "Create engaging blog posts and articles with AI assistance. Get help with outlines, content structure, and SEO-friendly writing.",
  tags: ["Content", "Writing", "SEO", "Blogging"],
  emoji: "✍️"
}

const INITIAL_MESSAGE: Message = {
  id: uuidv4(),
  role: 'assistant',
  content: "Hi! I'm your AI Blog Writing Assistant. I can help you create engaging articles, blog posts, and content. What would you like to write about?",
  timestamp: new Date()
}

export default function BlogBot() {
  const [messages, setMessages] = useState<Message[]>([INITIAL_MESSAGE])
  const [isLoading, setIsLoading] = useState(false)

  const handleSendMessage = (content: string) => {
    const userMessage: Message = {
      id: uuidv4(),
      role: 'user',
      content,
      timestamp: new Date()
    }

    setMessages(prev => [...prev, userMessage])
    setIsLoading(true)

    // Simulate bot response
    setTimeout(() => {
      const botMessage: Message = {
        id: uuidv4(),
        role: 'assistant',
        content: `I received your message about "${content}". This is a mock response for UI testing.`,
        timestamp: new Date()
      }
      setMessages(prev => [...prev, botMessage])
      setIsLoading(false)
    }, 1000)
  }

  return (
    <div className="flex flex-col bg-card rounded-lg border h-[calc(100vh-12rem)]">
      <div className="p-4 border-b">
        <div className="flex items-center gap-3">
          <span className="text-2xl">{botMeta.emoji}</span>
          <h1 className="text-xl font-semibold">{botMeta.name}</h1>
        </div>
        <p className="text-muted-foreground mt-1">{botMeta.description}</p>
      </div>
      <div className="flex-1 overflow-hidden">
        <ChatInterface
          messages={messages}
          onSendMessage={handleSendMessage}
          isLoading={isLoading}
          placeholder="Describe your blog post idea..."
        />
      </div>
    </div>
  )
}
// client/src/components/chat/ChatInterface.tsx
import { useState } from 'react'
import MessageList from './MessageList'
import MessageInput from './MessageInput'
import type { Message } from '@/types/chat'

interface ChatInterfaceProps {
  messages: Message[]
  onSendMessage: (message: string) => void
  isLoading?: boolean
  placeholder?: string
}

export default function ChatInterface({ 
  messages, 
  onSendMessage, 
  isLoading = false,
  placeholder = 'Type a message...'
}: ChatInterfaceProps) {
  return (
    <div className="flex flex-col h-full">
      <MessageList messages={messages} />
      <div className="border-t p-4">
        <MessageInput 
          onSend={onSendMessage} 
          isLoading={isLoading}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}